import Piechart from "../../../Components/Charts/PieChart";
import TaskItem from "../../../Components/EntTaskItem/TaskItem";
import ProgressData from "../../../Data/ProgressData";
import ChartData from "../../../Data/ChartData";
import "./Dashboard.css";
import EngrTasks from "../../../Components/EngrTasks/EngrTasks";
import BarChart from "../../../Components/Charts/BarChart";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import { useEffect, useState } from "react";
import Dot from "../../../Components/Dot/Dot";
import Role from "../../../Data/Role";
import { Get, Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const Dashboard = () => {
	const [EntList, setEntList] = useState([]);
	const [OpList, setOpList] = useState([]);
	const [Tasks, setTasks] = useState({ previous: [], current: [] });
	const [appData, setApp] = useState();
	const getRange = (monthRange) => {
		const d = new Date();
		if (monthRange) {
			const d1 = d.toISOString();
			d.setMonth(d.getMonth() - 1);
			const d2 = d.toISOString();
			return [d1, d2];
		} else {
			const d1 = d.toISOString();
			d.setFullYear(d.getFullYear() - 1);
			const d2 = d.toISOString();
			return [d1, d2];
		}
	};
	useEffect(() => {
		const pageination = {
			currentTask: 0,
			previousTask: 0,
			taskTypeRange: getRange(),
			engineerTaskRange: getRange(true),
			jobOverview: getRange(true),
		};
		Post(Url.enterprise.dashboard, pageination)
			.then((resp) => {
				console.log(resp);
				
				setEntList(resp.engineers.content);
				setOpList(resp.operations.content);
				setApp(resp.appSettings);
				setTasks({
					current: resp.currentTasks.content,
					previous: resp.previousTasks.content,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
	];
	return (
		<div className="Dashboard">
			<div className="DBLeft">
				<div className="DBLeftHead">
					<div>
						<h2>Dashboard</h2>
					</div>
					<div>
						<span className="bell">
							<Svg>{SvgOptions.Bell}</Svg>
						</span>
					</div>
				</div>
				<div className="DBLeftTop">
					<div>
						<div className="DBPieChart">
							{appData && <Piechart>{appData}</Piechart>}
						</div>
					</div>
					<div className="EngineersTasks">
						<Carousel showThumbs={false} showStatus={false} emulateTouch={true}>
							<div className="TaskCarousel">
								<div className="ETHead">
									<div>
										<h4>Engineers tasks</h4>
									</div>
									<div>{months[new Date().getMonth()]}</div>
								</div>
								<div className="EngrsTaskList">
									{EntList.map((ent, index) => (
										<EngrTasks key={index}>{ent}</EngrTasks>
									))}
								</div>
							</div>
							<div className="TaskCarousel">
								<div className="ETHead">
									<div>
										<h4>Operations tasks</h4>
									</div>
									<div>{months[new Date().getMonth()]}</div>
								</div>
								<div className="EngrsTaskList">
									{OpList &&
										OpList.map((op, index) => (
											<EngrTasks ops={true} key={index}>
												{op}
											</EngrTasks>
										))}
								</div>
							</div>
						</Carousel>
					</div>
				</div>
				<Carousel showThumbs={false} showStatus={false} emulateTouch={true}>
					<div className="DBLeftRow">
						{EntList?.length && (
							<BarChart chartId="chartEng">{EntList}</BarChart>
						)}
					</div>
					<div className="DBLeftRow">
						{OpList?.length && (
							<BarChart op={true} chartId="chartOp">
								{OpList}
							</BarChart>
						)}
					</div>
				</Carousel>
			</div>
			<div className="DBRight">
				<h3>{Tasks.current.length} Ongoing Tasks</h3>
				<div className="TaskItems">
					{Tasks.current.map((task, index) => (
						<TaskItem key={index}>{task}</TaskItem>
					))}
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
