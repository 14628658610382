export default class Calc {
	constructor(tastExpense) {
		this.tastExpense = tastExpense;
	}
	getPaid() {
		const paid = this.tastExpense?.expensesList
			?.filter((e) =>
				e.expenseApprovals != null ? e?.expenseApprovals?.status : false
			)
			.filter((e) =>
				e?.expenseApprovals?.payment != null
					? e?.expenseApprovals?.payment?.status
					: false
			)
			.reduce((a, v) => a + v?.expenseApprovals?.payment?.amount, 0);
		return paid;
	}
	getBalance(css) {
		const total = this.tastExpense?.task?.expenses?.total;
		const paid = this.getPaid();
		if (total >= paid)
			return <div className={css.amountValue}>₦{total - paid}</div>;
		else return <div className={css.debitValue}>- ₦{paid - total}</div>;
	}
}
