import Button from "../../Components/Button/Button";
import css from "./AddTaskModal.module.css";
import SvgOptions from "../../Components/Svg/SvgOptions";
import Svg from "../../Components/Svg/Svg";
import { useState, useEffect } from "react";
import Expandable from "../../Components/Expandable/Expandable";
import Form from "../../Components/Form/Form";
import FormType from "../../Components/Form/FormType";
import Utils from "../../Services/Utills";
const AddMailCopy = ({ children, onNext, onBack }) => {
	const [expand, setExpand] = useState(true);
	const [recipients, setRecipients] = useState([]);
	const [count, setCount] = useState(0);
	useEffect(() => {
		children.AddMailCopyModal = recipients;
	}, [recipients]);
	const onSubmit = (e) => {
		const dt = Utils.FormObject(e);
		console.log(dt);
		setRecipients((prev) => [...prev, { index: count, ...dt }]);
		setCount(count + 1);
		setExpand(false);
	};
	const handleDelete = () => {};
	const handleNext = () => {
		onNext && onNext(recipients);
	};
	const handleBack = () => {
		onBack && onBack();
	};

	const fmoption = {
		Fields: [
			{ name: "name", label: "Name", element: FormType.Input },
			{ name: "email", label: "Email", element: FormType.Input },
		],
		Buttons: [{ text: "Save", className: "Btn-Mid Active" }],
	};
	return (
		<div className={css.Cont}>
			<div className={css.Head}>
				<h2>Add Mail Recipients</h2>
				<Button
					onClick={() => setExpand(!expand)}
					className="Btn-Mid Active"
					icon={SvgOptions.Add}
				></Button>
			</div>
			<div>
				<Expandable expand={expand} height={240}>
					<div style={{ margin: "10px" }}>
						<Form onSubmit={onSubmit}>{fmoption}</Form>
					</div>
				</Expandable>
			</div>

			<div className={css.Body}>
				<div className={css.Engineers}>
					{recipients.map((r) => (
						<RecipientView onDelete={handleDelete} key={r.index}>
							{r}
						</RecipientView>
					))}
				</div>
				<div className={css.Controls}>
					<div>
						<Button onClick={handleBack} className="Btn-Mid Inverse">
							Back
						</Button>
					</div>
					<div>
						<Button onClick={handleNext} className="Btn-Mid Active">
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const RecipientView = ({ children, onDelete }) => {
	const { name, email } = children;
	const handelDelete = () => {
		onDelete && onDelete(children);
	};
	return (
		<div className={css.EquipmentView}>
			<div>
				<div>{name}</div>
				<div onClick={handelDelete} className={css.DeleteIcon}>
					<Svg>{SvgOptions.Delete}</Svg>
				</div>
			</div>
			<div>{email}</div>
		</div>
	);
};

export default AddMailCopy;
