import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import css from "./Jobs.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import DataTable from "../../../Components/DataTable/DataTable";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import Time from "../../../Services/Time";

const statusText = {
	initial: "New Task",
	requirements: "Requirements",
	risk: "Risk Assessment",
	contract: "Contract",
	null: "null",
};

const reader = (e) => {
	const statusIndex = e.status
		.split(",")
		.splice(1)
		.findIndex((s) => s < 3);
	const status = Object.keys(statusText)[statusIndex];
	const delivery = e.createdAt ? Time.getDate(e.createdAt) : "null";
	return [
		e.vessel,
		e.company,
		e.port,
		<div className={css[status] + " " + css.status}>{statusText[status]}</div>,
		delivery,
		"View",
	];
};

const JobReader = (rec) => {
	return [rec.vessel.name, rec.companyName, rec.site, "Starlink", "View"];
};

const JobsList = ({ navoption }) => {
	const headings = {
		pending: ["Company", "Site", "Equipment", ""],
		ongoing: ["Company", "Location", "Status", "Estimated Delivery", ""],
		completed: ["Company", "Location", "Status", "Estimated Delivery", ""],
	};

	const [view, setView] = useState("pending");
	const [data, setData] = useState({});
	const [page, setPage] = useState({ pageable: null, totalPage: null });
	const navigate = useNavigate();
	const headingFlex = [20, 20, 20, 15, 15, 10];

	useEffect(() => {
		loadData("pending", 0);
		loadData("ongoing", 0);
		loadData("completed", 0);
	}, []);

	const loadData = (type, pg) => {
		let url;
		if (type === "pending") url = Url.sales.getAllMarine(false, pg);
		else if (type === "ongoing") url = Url.sales.getAllInitDTO(false, pg);
		else if (type === "completed") url = Url.sales.getAllInitDTO(true, pg);

		Get(url).then((resp) => {
			setData((prev) => ({ ...prev, [type]: { ...resp, type } }));
		});
	};

	const handlePagination = (pg) => {
		loadData(view, pg);
	};

	const handleRowClick = (e) => {
		if (view === "pending") {
			navigate("CreateStarlink", { state: e });
		} else {
			navigate(`${e.id}`, { state: e });
		}
	};

	const dropOptions = {
		onClick: (e) => {
			const selectedOption = e.find((o) => o.selected);
			if (selectedOption) {
				const selectedView = selectedOption.text.toLowerCase();
				setView(selectedView);
			}
		},
		list: ["Pending", "Ongoing", "Completed"],
	};

	return (
		<div className={css.Jobs}>
			<PageHead
				initiateBtn={{ text: "Job", onClick: () => navigate("Create") }}
				navoption={navoption}
				dropOptions={dropOptions}
				filter={"Pending"}
			/>
			<div>
				{data[view]?.content && (
					<DataTable
						flex={headingFlex}
						handlePagination={handlePagination}
						reader={view === "pending" ? JobReader : reader}
						onClick={handleRowClick}
						pageable={data[view].pageable}
						totalPages={data[view].totalPages}
					>
						{{
							heading: [
								`${view?.charAt(0).toUpperCase() + view?.slice(1)} Jobs`,
								...headings[view],
							],
							data: data[view].content,
						}}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default JobsList;
