import Button from "../../Components/Button/Button";
import css from "./AddTaskModal.module.css";
import React from "react";
import SvgOptions from "../../Components/Svg/SvgOptions";
import Svg from "../../Components/Svg/Svg";
import Expandable from "../../Components/Expandable/Expandable";
import AddEquipment from "../../Components/AddEquipment/AddEquipment";
import { useState, useEffect } from "react";
import Utils from "../../Services/Utills";
const AddEquipmentModal = ({ onNext, onBack, children, onSet }) => {
	const [expand, setExpand] = useState(true);
	const [equipmentList, setEquipment] = useState([]);
	useEffect(() => {
		children.AddEquipmentModal = equipmentList;
		onSet(children);
	}, [equipmentList]);
	const onSubmit = (data) => {
		setEquipment([
			...equipmentList,
			{ index: equipmentList.length, equipment: Utils.FormObject(data) },
		]);
		setExpand(false);
	};
	const onDelete = (item) => {
		setEquipment(equipmentList.filter((eq) => eq.index !== item.index));
	};
	const handleNext = () => {
		onNext && onNext(equipmentList);
	};
	const handleBack = () => {
		onBack && onBack();
	};
	return (
		<div className={css.Cont}>
			<div className={css.Head}>
				<h2>Add Equipment</h2>
				<Button
					onClick={() => setExpand(!expand)}
					className="Btn-Mid Active"
					icon={SvgOptions.Add}
				></Button>
			</div>
			<div>
				<Expandable expand={expand} height={240}>
					<AddEquipment onSubmit={onSubmit}></AddEquipment>
				</Expandable>
			</div>
			<div className={css.Body}>
				<div className={css.EquipmentList}>
					{equipmentList.map((equipment, index) => (
						<EquipmentView onDelete={onDelete} key={index}>
							{equipment}
						</EquipmentView>
					))}
				</div>
				<div className={css.Controls}>
					<div>
						<Button onClick={handleBack} className="Btn-Mid Inverse">
							Back
						</Button>
					</div>
					<div>
						<Button onClick={handleNext} className="Btn-Mid Active">
							Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const EquipmentView = ({ children, onDelete }) => {
	const { equipment, template } = children.equipment;
	const handelDelete = () => {
		onDelete && onDelete(children);
	};
	return (
		<div className={css.EquipmentView}>
			<div>
				<div>{equipment}</div>
				<div onClick={handelDelete} className={css.DeleteIcon}>
					<Svg>{SvgOptions.Delete}</Svg>
				</div>
			</div>
			<div>{template.name}</div>
		</div>
	);
};

export default AddEquipmentModal;
