import "./Tasks.css";
import DataTable from "../../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import PageHead from "../../../Components/PageHead/PageHead";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import User from "../../../Services/User";
import { useState, useEffect } from "react";
import AddTaskWizard from "../../../Modals/AddEnterpriseTaskModal/AddTaskWizard";
import AddPendingTaskWizard from "../../../Modals/AddPendingEnterpriseTask/AddTaskWizard";
const TaskReader = (rec) => {
	return [
		rec.vesselName,
		rec.jobNumber,
		rec.companyName,
		rec.location,
		rec.createdAt,
	];
};
const JobReader = (rec) => {
	return [rec.vessel, rec.equipment, rec.company, rec.port, rec.createdAt];
};

const TasksView = ({ navoption, ModalAction }) => {
	const role = User.getRole();
	const heading = ["Job Number", "Company", "Location", "Started on"];
	const jobHeading = ["Job", "Company", "Location", "Started on"];
	const Navigate = useNavigate();
	const [current, setCurrent] = useState({
		heading: ["Ongoing Tasks", ...heading],
	});
	const [previous, setPrevious] = useState({
		heading: ["Completed Tasks", ...heading],
	});
	const [pending, setPending] = useState({
		heading: ["Pending Tasks", ...jobHeading],
	});
	const [closed, setClosed] = useState({
		heading: ["Closed Tasks", ...jobHeading],
	});
	const [view, setView] = useState("current");

	const loadPending = (pg) => {
		Get(Url.sales.getAllEnterpriseInitDTO(true, pg)).then((resp) => {
			setPending({ ...pending, data: resp });
		});
	};

	const loadCurrent = (page) => {
		Get(Url.enterprise.userTaskList(true, page))
			.then((resp) => {
				setCurrent({ ...current, data: resp });
			})
			.catch((err) => {
				Error(err);
			});
	};
	const loadPrevious = (page) => {
		Get(Url.enterprise.userTaskList(false, page))
			.then((resp) => {
				setPrevious({ ...previous, data: resp });
			})
			.catch((err) => {
				Error(err);
			});
	};
	const loadClosed = (page) => {
		Get(Url.enterprise.closedTasks(page))
			.then((resp) => {
				setView({ ...current, data: resp });
			})
			.catch((err) => {
				Error(err);
			});
	};

	const loadData = (page) => {
		loadCurrent(page);
		loadPrevious(page);
		loadPending(page);
		loadClosed(page);
	};

	useEffect(() => {
		loadData(0);
	}, []);

	const onTaskClick = (e) => {
		if (role.name == "account") Navigate(`Expenses/${e.id}`);
		else Navigate(`${e.id}`);
	};
	const flex = [20, 20, 20, 20, 20];
	const btns = navoption.controls();
	const handleTemplate = () => {
		Navigate("MilestoneTemplates");
	};
	const handleAddTask = () => {
		const Options = {
			title: "Add Task",
			element: AddTaskWizard,
			closeModal: (resp) => {
				loadData(0);
				ModalAction.closeModal();
			},
		};
		ModalAction.openModal(Options, "Add Task");
	};
	if (Array.isArray(btns)) {
		btns.forEach((btn) => {
			if (btn.id === 1) btn.onClick = handleTemplate;
			else if (btn.id === 4) btn.onClick = () => Navigate("report");
			else btn.onClick = handleAddTask;
		});
	}
	const handleNextCurrent = (page) => {
		loadCurrent(page);
	};
	const handleNextPending = (page) => {
		loadPending(page);
	};
	const handleNextPrevious = (page) => {
		loadPrevious(page);
	};
	const dropOptions = {
		onClick: (e) => {
			const op = e.find((o) => o.selected);
			if (!op) {
				setView("current");
			} else {
				if (op.text === "Ongoing Tasks") setView("current");
				else if (op.text === "Pending Tasks") setView("pending");
				else if (op.text === "Completed Tasks") setView("previous");
				else if (op.text === "Closed Tasks") setView("closed");
			}
		},
		list: ["Ongoing Tasks", "Pending Tasks", "Completed Tasks", "Closed Tasks"],
	};

	const handlePending = (e) => {
		const Options = {
			title: "Add Task",
			element: AddPendingTaskWizard,
			jobId: e.id,
			closeModal: (resp) => {
				loadData(0);
				ModalAction.closeModal();
			},
		};
		ModalAction.openModal(Options, "Add Task");
	};
	return (
		<div className="Tasks">
			<PageHead
				dropOptions={dropOptions}
				navoption={navoption}
				buttons={btns}
				title="Tasks"
				filter={"Ongoing Tasks"}
			></PageHead>
			<div className="CurrentTasks">
				{view === "current" && current.data && (
					<DataTable
						handlePagination={handleNextCurrent}
						reader={TaskReader}
						onClick={onTaskClick}
						flex={flex}
					>
						{current}
					</DataTable>
				)}
				{view === "pending" && pending.data && (
					<DataTable
						handlePagination={handleNextPending}
						reader={JobReader}
						onClick={handlePending}
						flex={flex}
					>
						{pending}
					</DataTable>
				)}
				{view === "previous" && previous.data && (
					<DataTable
						handlePagination={handleNextPrevious}
						reader={TaskReader}
						onClick={onTaskClick}
						flex={flex}
					>
						{previous}
					</DataTable>
				)}
				{view === "closed" && closed.data && (
					<DataTable
						handlePagination={handleNextPrevious}
						reader={TaskReader}
						onClick={onTaskClick}
						flex={flex}
					>
						{closed}
					</DataTable>
				)}
			</div>
		</div>
	);
};
export default TasksView;
