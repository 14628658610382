import css from "./AddTaskModal.module.css";
import Button from "../../Components/Button/Button";
import { useState } from "react";
import SvgOptions from "../../Components/Svg/SvgOptions";
import Svg from "../../Components/Svg/Svg";
import Expandable from "../../Components/Expandable/Expandable";
import AddEngineer from "../../Components/AddEngineer/AddEngineer";
import Utils from "../../Services/Utills";
import { toast } from "react-toastify";

import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
import User from "../../Services/User";
import { ThreeDots } from "react-loader-spinner";

const AddEngineersModal = ({ onNext, onBack, children, onClose }) => {
	const [expand, setExpand] = useState(true);
	const [saving, setSaving] = useState(false);
	const [Engineers, setEngineers] = useState([]);
	const [count, setCount] = useState(0);
	console.log(children);
	const handleNext = () => {
		if (Engineers.length) {
			setSaving(true);
			children.AddEngineersModal = Engineers;
			const { vessel, location, serviceOrder, taskOptions, deliveryEstimate } =
				children.AddTaskModal;
			const data = {
				vessel,
				location,
				serviceOrder,
				taskOptions,
				deliveryEstimate,
			};
			data.taskEngineers = Engineers.reduce(
				(a, v) => [...a, { engineer: { id: v.engineer.id }, lead: v.lead }],
				[]
			);
			data.taskOperations = [
				{ owner: true, operation: { id: User.getBiodata().id } },
			];
			data.taskEquipmentList = children.AddEquipmentModal.reduce(
				(a, v) => [
					...a,
					{
						name: v.equipment.equipment,
						type: v.equipment.type,
						taskMilestones: v.equipment.template.milestones.reduce((x, y) => {
							delete y.id;
							y.status = { id: 1 };
							return [...x, y];
						}, []),
					},
				],
				[]
			);
			data.taskMailCopies = children.AddMailCopyModal.reduce(
				(a, v) => [...a, { name: v.name, email: v.email }],
				[]
			);
			Post(Url.saveTask, data)
				.then(() => {
					toast("Task saved");
				})
				.catch(() => {
					toast("Error saving task");
				})
				.finally(() => {
					setSaving(false);
					onClose && onClose(data);
				});
			//onNext&&onNext(Engineers);
		} else toast("You must add at least one engineer");
	};
	const handleBack = () => {
		onBack && onBack();
	};
	const onSubmit = (engr) => {
		const dt = Utils.FormObject(engr);
		setEngineers((prev) => [
			...prev,
			{ index: count, ...dt, lead: prev.length === 0 },
		]);
		setCount(count + 1);
		setExpand(false);
	};
	const setLead = (engr) => {
		setEngineers(
			Engineers.reduce((a, v) => [...a, { ...v, lead: v === engr }], [])
		);
	};
	const handleDelete = (engr) => {
		const list = Engineers.filter((item) => item.index !== engr.index);
		if (engr.lead && list.length) list[0].lead = true;
		setEngineers(list);
	};
	return (
		<>
			<div className={css.Cont} style={{ display: saving ? "none" : "flex" }}>
				<div className={css.Head}>
					<h2>Assign Engineers</h2>
					<Button
						onClick={() => setExpand(!expand)}
						className="Btn-Mid Active"
						icon={SvgOptions.Add}
					></Button>
				</div>
				<div>
					<Expandable expand={expand} height={150}>
						<AddEngineer onSubmit={onSubmit}></AddEngineer>
					</Expandable>
				</div>

				<div className={css.Body}>
					<div className={css.Engineers}>
						{Engineers.map((Engr) => (
							<EngineerView
								onDelete={handleDelete}
								onSetLead={setLead}
								key={Engr.index}
							>
								{Engr}
							</EngineerView>
						))}
					</div>
					<div className={css.Controls}>
						<div>
							<Button onClick={handleBack} className="Btn-Mid Inverse">
								Back
							</Button>
						</div>
						<div>
							<Button onClick={handleNext} className="Btn-Mid Active">
								Save
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className={css.Cont} style={{ display: saving ? "flex" : "none" }}>
				<h2>Saving task...</h2>
				<ThreeDots
					height="80"
					width="80"
					radius="9"
					color="#14B8FF"
					ariaLabel="three-dots-loading"
					wrapperStyle={{}}
					wrapperClassName=""
					visible={true}
				/>
			</div>
		</>
	);
};
const EngineerView = ({ children, onSetLead, onDelete }) => {
	const { engineer, lead } = children;
	return (
		<div className={css.EngineerView}>
			<h5>{engineer.name}</h5>
			<div className={css.Lead}>
				{lead ? (
					<h5>
						Lead <Svg className={css.EngIcon}>{SvgOptions.Engineer}</Svg>{" "}
					</h5>
				) : (
					<div className={css.BtnLead}>
						<Button
							className="Btn-Tny Inverse"
							onClick={() => onSetLead(children)}
						>
							Set as Lead
						</Button>
					</div>
				)}
				<Svg onClick={() => onDelete(children)} className={css.Delete}>
					{SvgOptions.Delete}
				</Svg>
			</div>
		</div>
	);
};

export default AddEngineersModal;
