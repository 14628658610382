import Button from "../../Components/Button/Button";
import Input from "../../Components/Form/Input/Input";
import css from "./style.module.css";
import { Post } from "../../Services/Server";
import Url from "../../Services/Url";
import { toast } from "react-toastify";
const PauseTaskModal = ({ children }) => {
	console.log(children);

	const { task, url } = children;
	const pausedTask = { label: "" };

	const exit = () => {
		children.pauseModal();
	};
	const save = () => {
		if (!pausedTask.value) {
			toast("Please give a reason for Pausing this task");
			return;
		}
		task.pausedTask = { details: pausedTask.value };
		Post(url, task)
			.then(() => {
				toast("Task Paused");
				children.onClosed();
			})
			.catch((err) => {});
	};
	return (
		<div className={css.Modal}>
			<div>
				<h4>You are about to pause {children.task.title}</h4>
			</div>
			<div>What is your reason?</div>
			<Input>{pausedTask}</Input>
			<div className={css.Controls}>
				<div>
					<Button onClick={save} className="Btn-Mid Active">
						Pause Task
					</Button>
				</div>
				<div>
					<Button onClick={exit} className="Btn-Mid Inverse">
						Exit Operation
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PauseTaskModal;
