import css from "./style.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import { Get } from "../../../Services/Server";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Url from "../../../Services/Url";
import DataTable from "../../../Components/DataTable/DataTable";

const Reader = (rec) => {
	const exp = rec;
	return [
		"₦" + exp.total,
		rec.jobNumber,
		exp.submittedBy,
		rec.location,
		exp.submittedAt,
	];
};

const ExpensesList = ({ navoption }) => {
	const heading = ["Job Number", "Submitted By", "Location", "Submitted on"];
	const Navigate = useNavigate();

	const [pending, setPending] = useState({
		heading: ["Pending Expenses", ...heading],
	});
	const [approved, setApproved] = useState({
		heading: ["Approved Expenses", ...heading],
	});
	const [scheduled, setScheduled] = useState({
		heading: ["Scheduled Expenses", ...heading],
	});
	const [disapproved, setDisapproved] = useState({
		heading: ["Disapproved Expenses", ...heading],
	});

	const [view, setView] = useState("pending"); // Default view

	const flex = [20, 20, 20, 20, 20];

	const loadPending = (page) => {
		Get(Url.expenses.tasksdto(false, page))
			.then((resp) => {
				setPending({ ...pending, data: resp });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadApproved = (page) => {
		Get(Url.expenses.tasksdto(true, page))
			.then((resp) => {
				setApproved({ ...approved, data: resp });
			})
			.catch((err) => {
				console.log(err);

			});
	};

	const loadScheduled = (page) => {
		Get(Url.expenses.completed(page))
			.then((resp) => {
				setScheduled({ ...scheduled, data: resp });
			})
			.catch((err) => {
				console.log(err);

			});
	};
	const loadDisapproved = (page) => {
		Get(Url.expenses.disapproved(page))
			.then((resp) => {
				setDisapproved({ ...disapproved, data: resp });
			})
			.catch((err) => {
				console.log(err);

			});
	};

	useEffect(() => {
		loadPending(0);
		loadApproved(0);
		loadScheduled(0);
		loadDisapproved(0);
	}, []);

	const onExpenseClick = (e) => {
		Navigate("" + e.id);
	};

	const handlePagination = (page) => {
		if (view === "pending") loadPending(page);
		else if (view === "approved") loadApproved(page);
		else if (view === "scheduled") loadScheduled(page);
		else if (view === "disapproved") loadDisapproved(page);
	};

	const dropOptions = {
		onClick: (e) => {
			const op = e.find((o) => o.selected);
			if (!op) {
				setView("pending"); // Default to "Pending Expenses"
			} else {
				if (op.text === "Pending Expenses") setView("pending");
				else if (op.text === "Approved Expenses") setView("approved");
				else if (op.text === "Scheduled Expenses") setView("scheduled");
				else if (op.text === "Disapproved Expenses") setView("disapproved");
			}
		},
		list: ["Pending Expenses", "Approved Expenses", "Scheduled Expenses", "Disapproved Expenses"],
	};

	return (
		<div className={css.Page}>
			<PageHead
				navoption={navoption}
				dropOptions={dropOptions}
				filter={"Pending Expenses"}
			></PageHead>
			<div>
				{view === "pending" && pending.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{pending}
					</DataTable>
				)}
				{view === "approved" && approved.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{approved}
					</DataTable>
				)}
				{view === "scheduled" && scheduled.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{scheduled}
					</DataTable>
				)}
				{view === "disapproved" && disapproved.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{disapproved}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default ExpensesList;
