import "./DataTable.css";
import Pagination from "../Pagination/Pagination";

const Rec = ({ children, flex, onClick, reader }) => {
	const data = reader ? reader(children) : children;
	return (
		<div className="TableRecord">
			{data.map((rec, index) => (
				<div
					onClick={() => (onClick ? onClick(children) : null)}
					style={{ flex: flex[index] + "%" }}
					key={index}
				>
					{rec}
				</div>
			))}
		</div>
	);
};

const DataTable = ({
	children,
	flex,
	onClick,
	reader,
	pageable,
	totalPages,
	handlePagination,
}) => {
	const { heading, data } = children;
	const dr = Array.isArray(data) ? data : data.content;
	const pg = Array.isArray(data) ? pageable : data.pageable;
	const tp = Array.isArray(data) ? totalPages : data.totalPages;

	return (
		<div className="DataTable">
			<div className="TableHead">
				{heading &&
					heading.map((head, index) => (
						<div style={{ flexBasis: flex[index] + "%" }} key={index}>
							{head}
						</div>
					))}
			</div>
			{dr?.length ? (
				<div className="TableRecords">
					{dr.map((rec, index) => (
						<Rec reader={reader} onClick={onClick} flex={flex} key={index}>
							{rec}
						</Rec>
					))}
				</div>
			) : (
				<div className="TableNoRecord">
					<center>No record available</center>
				</div>
			)}
			{pg && (
				<Pagination
					onClick={handlePagination}
					pageable={pg}
					totalPages={tp}
				></Pagination>
			)}
		</div>
	);
};
export default DataTable;
