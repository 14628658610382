import React from "react";
import css from "./style.module.css";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { useParams } from "react-router-dom";
import Head from "../../../Components/Head/Head";
import { useState, useEffect } from "react";
import DataTable from "../../../Components/DataTable/DataTable";
import Time from "../../../Services/Time";
import Button from "../../../Components/Button/Button";
import TaskItem from "../../../Components/TaskItem/TaskItem";
import ExpenseRemark from "../../../Modals/ExpenseRemark/ExpenseRemark";
import { ModalAction } from "../../../Routes/NavRouter";
import User from "../../../Services/User";
import { useNavigate } from "react-router-dom";
import ExpensesItem from "../../../Components/ExpensesItem/ExpensesItem";
import Calc from "../../../Services/Calc";
import ModalOption from "../../../Services/ModalOption";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
const Reader = (rec) => {
	return [
		rec.name,
		rec.quantity,
		"₦" + rec.price,
		"₦" + rec.quantity * rec.price,
		rec.receiptId ? (
			<div className={css.receiptThumb}>
				<img src={Url.img.thumb(rec.receiptId)} alt="receipt" width={200} />
			</div>
		) : (
			<Svg>{SvgOptions.Receipt}</Svg>
		),
	];
};

const ExpensesView = () => {
	const { id } = useParams();
	const Navigate = useNavigate();
	const [tastExpense, setExpense] = useState();
	const heading = ["Item Name", "Unit", "Price", "Amount", "Upload"];
	const biodata = User.getBiodata();
	const [current, setCurrent] = useState({ heading });
	const flex = [20, 20, 20, 20, 20];
	const submittedBy = () => {
		const user = tastExpense.task.expenses.submittedBy;
		return user.firstname + " " + user.lastname;
	};
	const submittedAt = () => {
		const at = tastExpense.task.expenses.submittedAt;
		return Time.getDateTime(at);
	};
	const ApprovedBy = () => {
		const by = tastExpense.task.expenses.expenseApprovals.approvedBy;
		return by.firstname + " " + by.lastname;
	};
	const PaidBy = () => {
		const by = tastExpense.task.expenses.expenseApprovals.payment.paidBy;
		return by.firstname + " " + by.lastname;
	};

	const init = () => {
		Get(Url.expenses.getEnterprise(id))
			.then((resp) => {
				console.log(resp);
				setExpense(resp);
				setCurrent({ ...current, data: resp.task.expenses.expenseDetailsList });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		init();
	}, []);

	const remark = (status) => {
		const { expenses } = tastExpense.task;
		//console.log(task)
		const options = {
			element: ExpenseRemark,
			title: "Payment Remark",
			expenses,
			status,
			amount: tastExpense.task.expenses.total - getPaid(),
			paidBy: { id: biodata.id },
			closeModal: () => {
				ModalAction.closeModal();
				Navigate("../");
			},
		};
		ModalAction.openModal(options);
	};
	let Cal;
	if (tastExpense) {
		Cal = new Calc(tastExpense);
	}
	const getPaid = () => {
		return Cal.getPaid();
	};
	const getBalance = () => {
		return Cal.getBalance(css);
	};

	const onExpenseClick = (e) => {
		const option = ModalOption();
		option.element = Expanded;
		option.title = e.name;
		option.expense = e;
		option.closeModal = ModalAction.closeModal();
		ModalAction.openModal(option);
	};

	return (
		<div className={css.TDPage}>
			{tastExpense && (
				<>
					<div className={css.TDLeft}>
						<Head title={"Expense for " + tastExpense.task.title}></Head>
						{/* <div>
							<div className={css.amountTitle}>Total Amount:</div>
							{getBalance()}
						</div> */}
						<div>
							{current.data && (
								<DataTable reader={Reader} onClick={onExpenseClick} flex={flex}>
									{current}
								</DataTable>
							)}
						</div>
						<div className={css.expenseFoot}>
							<div>
								<div>Total Expenses:</div>
								<div>₦{tastExpense.task.expenses.total}</div>
							</div>
							{/* <div>
								<div>Total Paid:</div>
								<div>₦{getPaid()}</div>
							</div> */}

							<div>
								<div>Submitted By:</div>
								<div>{submittedBy()} </div>
							</div>
							<div>
								<div>Submited At:</div>
								<div>{submittedAt()}</div>
							</div>
						</div>
						<div className={css.Approvals}>
							{tastExpense.task.expenses.expenseApprovals && (
								<div className={css.Approve}>
									{tastExpense.task.expenses.expenseApprovals.status ? (
										<div>
											<div className={css.Title}>Expense Approved</div>
											<div className={css.Details}>
												<div>Approved By : {ApprovedBy()}</div>
												<div>
													Approved At:{" "}
													{Time.getDateTime(
														tastExpense.task.expenses.expenseApprovals.createdAt
													)}
												</div>
											</div>
											<div className={css.Remark}>
												Remark :{" "}
												{tastExpense.task.expenses.expenseApprovals.remark}
											</div>
										</div>
									) : (
										<div className={css.Title}>Expense Disapproved</div>
									)}
								</div>
							)}
							{tastExpense.task.expenses.expenseApprovals.payment ? (
								<div>
									<div className={css.Approve}>
										<div className={css.Title}>Payment Approved</div>
										<div className={css.Details}>
											<div>Approved By : {PaidBy()}</div>
											<div>
												Approved At:{" "}
												{Time.getDateTime(
													tastExpense.task.expenses.expenseApprovals.payment
														.paidAt
												)}
											</div>
										</div>
										<div className={css.Remark}>
											Remark :{" "}
											{
												tastExpense.task.expenses.expenseApprovals.payment
													.remark
											}
										</div>
									</div>
								</div>
							) : (
								<div className={css.controls}>
									<div>
										<Button
											onClick={() => remark(true)}
											className="Active Btn-Mid"
										>
											Approve Payment
										</Button>
									</div>
									<div>
										<Button
											onClick={() => remark(true)}
											className="Inverse Btn-Mid"
										>
											Disapprove Payment
										</Button>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className={css.TDRight}>
						<div className={css.Title}>Task Details</div>
						<TaskItem>{tastExpense.task}</TaskItem>
						<div className={css.Title}>Related Expenses</div>
						<div className={css.ExpensesList}>
							{tastExpense.expensesList.map((exp) => (
								<ExpensesItem key={exp.id}>{exp}</ExpensesItem>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

const Expanded = ({ children }) => {
	const { expense } = children;
	console.log(Url.img.get(expense?.receiptId));
	return (
		<div className={css.ExpandedView}>
			<div className={css.ReceiptObject}>
				{expense?.receiptType.includes("pdf") ? (
					<object
						data={Url.img.get(expense?.receiptId)}
						width={800}
						height={500}
						type={expense?.receiptType}
					/>
				) : (
					<img src={Url.img.get(expense?.receiptId)} />
				)}
			</div>
		</div>
	);
};

export default ExpensesView;
